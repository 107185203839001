<div style="position: relative; top: 0; left: 0; right: 0; height: 60px; display: flex; align-items: center; background-color: #0e7706; color: white; font-weight: 600;" role="banner">
    <!-- <div style="padding: 0px; height: 42px; width: 42px; border: 1px solid #000; margin-left: 5%; margin-right: 5%;">
      <img src="../../../assets/mtn-logo.png" style="width: 40px; height: 40px" />
    </div> -->
    <span style="color: #fff; margin-left: 3rem">FACIAL CAPTURE</span>
    <div class="spacer"></div>
  </div>
  
  <div class="content" role="main">
    <div class="row">
      <!-- <div class="col-12" style="margin-top: 75px">
        <div
          class="row"
          style="
            margin-bottom: 25px;
            margin-top: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <img [src]="transform()" style="width: 160px; height: 210px" />
        </div>
      </div> -->
  
      <div
        class="col-12"
        style="
          display: flex;
          position: absolute;
          bottom: 40px;
          justify-content: center;
          align-items: center;
        "
      >
        <div class="row" style="align-content: center">
          <button
            class="btn btn-primary btn-block"
            style="color: #fff !important; padding: 10px 35px"
            (click)="refreshCapture()"
          >
            Refresh
          </button>
        </div>
      </div>
    </div>
  </div>