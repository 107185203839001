import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from '../components/index/index.component';
import { BiometricCaptureComponent } from './biometric-capture/biometric-capture.component';
import { CaptureCompleteComponent } from './capture-complete/capture-complete.component';
import { LayoutComponent } from './layouts/layout.component';
import { UnicefCaptureComponent } from './unicef-capture/unicef-capture.component';
import { FaceCaptureComponent } from './face-capture/face-capture.component';
import { CrimsCaptureComponent } from './crims-capture/crims-capture.component';
import { NimcWebCaptureComponent } from './nimc-web-capture/nimc-web-capture.component';
import { FaceCaptureRearComponent } from './face-capture/face-capture-rear.component';

const routes: Routes = [
  // { path: 'sdk', component: IndexComponent },
  { path: 'biometric-capture/webhook/:webhook/auth_key/:auth_key/identifier/:identifier',component: BiometricCaptureComponent },
  { path: 'biometric2-capture/webhook/:webhook/auth_key/:auth_key/identifier/:identifier',component: BiometricCaptureComponent },
  { path: 'unicef-capture/webhook/:webhook/auth_key/:auth_key/identifier/:identifier',component: UnicefCaptureComponent },
  { path: 'crims-capture/webhook/:webhook/auth_key/:auth_key/identifier/:identifier',component: CrimsCaptureComponent },
  { path: 'biometric-capture/face-capture',component: FaceCaptureComponent },
  { path: 'biometric-capture/face-capture-rear',component: FaceCaptureRearComponent },
  { path: 'nimc-face-capture',component: NimcWebCaptureComponent },
  // { path: 'capture-complete', component: CaptureCompleteComponent },
  {
    path: 'feature', component: LayoutComponent, loadChildren: () => import('./shared-layout/shared-layout.module').then(m => m.SharedLayoutModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
